<template>

    <div class="card">
        <div class="form-group row">
            <label for="name" class="col-md-4 col-form-label text-md-right">Naam</label>
            <div class="col-md-6">
                <input class="form-control" id="name" type="text" v-model="product.name">
            </div>
        </div>
        <div class="form-group row">
            <label for="description" class="col-md-4 col-form-label text-md-right">Beschrijving</label>
            <div class="col-md-6">
                <input class="form-control" id="model" type="text" v-model="product.description">
            </div>
        </div>
        <div class="form-group row">
            <label for="price" class="col-md-4 col-form-label text-md-right">Prijs</label>
            <div class="col-md-6">
                <input class="form-control" id="model" type="number" v-model="product.price">
            </div>
        </div>
        <div class="form-group row">
            <label for="active" class="col-md-4 col-form-label text-md-right">Op voorraad</label>
            <div class="col-md-6">
                <input class="form-control" id="model" type="checkbox" v-model="product.active">
            </div>
        </div>

        <div class="row">
            <label for="image" class="col-md-4 col-form-label text-md-right">Product afbeelding</label>
            <div class="col-md-6">
                <input type="file" accept="image/*" @change="setImage($event)" id="file-input">
            </div>
        </div>




        <div class="form-group row mb-0">
            <div class="col-md-6 offset-md-4">
                <button v-on:click="add" class="btn btn-primary">
                    Aanmaken
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios'

export default {
    name: 'createProduct',
    components:{

    },
    data() {
        return {
            product: {
                id: null,
                name: null,
                price: null,
                description: null,
                active: true,
                image: null,
            }
        }
    },
    mounted() {
    },

    computed: {

    },

    methods:{

        add() {
            axios.post('/products/add', this.product).then(() => location.reload());
        },

        setImage: function (e) {
            const file = e.target.files[0]
            console.log(file)

            if (!file.type.includes('image/')) {
                alert('Please select an image file')
                return
            }

            if (typeof FileReader === 'function') {
                const reader = new FileReader()

                reader.onload = (event) => {
                    this.product.image = event.target.result

                    // rebuild cropperjs with the updated source

                }
                reader.readAsDataURL(file)
            } else {
                alert('Sorry, FileReader API not supported')
            }
        },

    }

}
</script>


