
import Vue from 'vue'
import tableOrder from "./components/tableOrder"
import createProduct from "./components/createProduct"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

library.add(faShoppingCart)
library.add(faTrash)

Vue.use(VueSweetalert2);
Vue.component("font-awesome-icon", FontAwesomeIcon);



const app = new Vue (
    {
        el: '#app',
        components: {
        tableOrder,
            createProduct,
        }
    }
)
