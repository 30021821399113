<template>
    <div class="container">
        <div>
            <div class="row">
                <div class="col-8">
                    <div class="text-center">
                        <h4 v-if="event">Welkom bij {{ event.name }}</h4>
                        <h5>Veel plezier!</h5>
                    </div>
                </div>
                <div class="col-4 text-end" style="position: sticky; top:0">
                    <button type="button" class="btn" @click="showModal">
                        <font-awesome-icon class="fa-3x text-success" icon="shopping-cart" />
                    </button>
                </div>
            </div>
        </div>
    <div v-if="event.active">
        <div class="row justify-content-center">
            <div class="col-6 d-flex align-items-stretch" v-for="product in products" :key="product._id">
                <div class="card product-card">
                    <div class="image-container">
                        <img class="card-img-top my-image" v-bind:src="product.image" />
                    </div>
                    <div class="card-body flex-grow-1 d-flex flex-column justify-content-between text-center">
                        <p>{{ product.name }} <br> Prijs: {{ product.price }} munt</p>
                        <button class="btn btn-success mt-auto" v-on:click="add(product)">Bestel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <h4 class="text-center" v-else>Het bestellen met qr code is gesloten!</h4>


        <shopping-cart v-show="isModalVisible" @close="closeModal">
            <template v-slot:header>
                Winkelwagen:
            </template>

            <template v-slot:body>
                <table v-if="orderItems.length > 0" class="table table-striped">
                    <thead>
                    <th>Product</th>
                    <th>Aantal</th>
                    <th>Acties</th>
                    </thead>
                    <tbody>
                    <tr v-for="orderItem in orderItems" :key="orderItem.product_id">
                        <td>{{ orderItem.name }}</td>
                        <td style="width: 50%">
                            <button class="btn btn-success me-2" v-on:click="decreaseAmount(orderItem)">-</button>
                            {{ orderItem.amount }}
                            <button class="btn btn-success ms-2" v-on:click="increaseAmount(orderItem)">+</button>
                        </td>
                        <td style="width: 3%">
                            <button class="btn btn-danger" v-on:click="removeItem(orderItem)">
                                <font-awesome-icon class="fa-1x" icon="trash" />
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Totaal: {{ totalPrice }} munten</td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                <h4 class="text-center" v-else>Uw winkelwagen is leeg!</h4>
                <div v-if="orderItems.length > 0" class="col-md-6">
                    <label for="description" class="col-md-4 col-form-label text-md-right">Opmerkingen</label>
                    <div class="col-md-6">
                        <input
                            class="form-control"
                            id="comment"
                            type="text"
                            v-model="comment"
                            @input="enforceMaxLength"
                        >
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <div v-if="orderItems.length > 0">
                    <button class="btn btn-success" v-on:click="processOrder()">Plaats bestelling!</button>
                </div>
            </template>
        </shopping-cart>
    </div>
</template>

<script>
import axios from 'axios'
import ShoppingCart from "./shoppingCart.vue";
import swal from 'sweetalert2'

export default {
    name: 'tableOrder',
    components: {
        ShoppingCart
    },
    props: {
        eventId: {
            type: Number
        },
        tableId: {
            type: Number
        },
    },
    data() {
        return {
            isModalVisible: false,
            products: [],
            orderItems: [],
            totalPrice: 0,
            comment: "",
            orderItem: {
                product_id: null,
                name: null,
                total: null,
                amount: null,
            },
            event: null,
            order: {
                comment: "",
                orderItems: [],
                tableId: null
            }
        }
    },
    mounted() {
        axios.get('/products/apiproducts/' + this.eventId)
            .then(res => (this.products = res.data.products))
            .catch(err => console.log(err));

        axios.get('/events/apievent/' + this.eventId)
            .then(res => (this.event = res.data))
            .catch(err => console.log(err));
    },
    methods: {
        enforceMaxLength() {
            if (this.comment.length > 200) {
                this.comment = this.comment.substring(0, 200); // Trim extra characters
            }
        },
        add(product) {
            let existingItem = this.orderItems.find(orderItem => orderItem.product_id === product.id);

            if (!existingItem) {
                this.orderItems.push({
                    product_id: product.id,
                    name: product.name,
                    amount: 1,
                    total: product.price,
                    price: product.price
                });
            } else {
                this.increaseAmount(existingItem);
            }

            this.calcTotalPrice();
            swal.fire({
                text: product.name + " is toegevoegd aan bestelling!",
                toast: true,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    popup: 'colored-toast'
                },
                icon: 'success',
                position: 'top-right',
            });
        },
        removeItem(item) {
            this.orderItems = this.orderItems.filter(orderItem => orderItem !== item);
            this.calcTotalPrice();
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        increaseAmount(item) {
            if (item.amount < 20) {
                item.amount += 1;
                item.total = item.amount * item.price;
                this.calcTotalPrice();
            }
        },
        decreaseAmount(item) {
            if (item.amount > 1) {
                item.amount -= 1;
                item.total = item.amount * item.price;
                this.calcTotalPrice();
            }
        },
        processOrder() {
            this.order.orderItems = this.orderItems;
            this.order.comment = this.comment;
            this.order.tableId = this.tableId;
            axios.post("/orders/add", this.order)
                .then(() => {
                    swal.fire('Gelukt!', 'Uw bestelling is geplaatst!', 'success');
                    location.reload();
                })
                .catch(error => {
                    swal.fire('Mislukt!', error.response.data.error, 'error');
                });
        },
        calcTotalPrice() {
            this.totalPrice = this.orderItems.reduce((sum, item) => sum + item.total, 0);
        }
    }
}
</script>

<style>
/* Make sure the container doesn't touch the edges */
.container {
    max-width: 95%;
    margin: auto;
}

/* Make sure two cards fit per row and adjust for smaller screens */
.product-card {
    width: 100%;  /* Make sure it scales properly */
    max-width: 280px;  /* Limit the width to fit two in a row */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Image settings */
.image-container {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
</style>
